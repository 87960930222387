const labs = {
  Bader: {
    lab: 'Bader',
    PI: 'Gary D. Bader',
    website: 'http://www.baderlab.org/',
    scholar_id: '22M9eisAAAAJ',
    group: 'Scientist',
  },
  Bedard: {
    lab: 'Bedard',
    PI: 'Philippe Bedard',
    website: 'https://www.uhnresearch.ca/researcher/philippe-bedard',
    scholar_id: 'Tp-NMz0AAAAJ',
    group: 'Clinician',
  },
  Berlin: {
    lab: 'Berlin',
    PI: 'Alejandro Berlin',
    website: 'https://radonc.utoronto.ca/faculty/alejandro-berlin',
    scholar_id: '6RuFEicAAAAJ',
    group: 'Clinician',
  },
  Brudno: {
    lab: 'Brudno',
    PI: 'Michael Brudno',
    website: 'https://brudno.uhndata.io/',
    scholar_id: 'xoNrEqUAAAAJ',
    group: 'Scientist',
  },
  Cescon: {
    lab: 'Cescon',
    PI: 'David Cescon',
    website: 'https://www.uhnresearch.ca/researcher/david-cescon',
    scholar_id: 'c2PH_FoAAAAJ',
    group: 'Clinician',
  },
  Gaiti: {
    lab: 'Gaiti',
    PI: 'Federico Gaiti',
    website: 'https://www.gaitilab.com/team',
    scholar_id: 'MRlCOU4AAAAJ',
    group: 'Scientist',
  },
  Grant: {
    lab: 'Grant',
    PI: 'Robert Grant',
    website: '',
    scholar_id: '',
    group: 'Clinician',
  },
  'Haibe-Kains': {
    lab: 'Haibe-Kains',
    PI: 'Benjamin Haibe-Kains',
    website: 'https://www.pmgenomics.ca/bhklab/',
    scholar_id: 'hfGa2RMAAAAJ',
    group: 'Scientist',
  },
  Haider: {
    lab: 'Haider',
    PI: 'Masoom Haider',
    website: 'https://medical-imaging.utoronto.ca/faculty/masoom-haider',
    scholar_id: '5gnQmpAAAAAJ',
    group: 'Clinician',
  },
  Hoffman: {
    lab: 'Hoffman',
    PI: 'Michael Hoffman',
    website: 'https://hoffmanlab.org/',
    scholar_id: '96r1DYUAAAAJ',
    group: 'Scientist',
  },
  Hope: {
    lab: 'Hope',
    PI: 'Andrew Hope',
    website: 'https://www.uhnresearch.ca/researcher/andrew-hope',
    scholar_id: '',
    group: 'Clinician',
  },
  Kridel: {
    lab: 'Kridel',
    PI: 'Robert Kridel',
    website: 'https://www.uhnresearch.ca/researcher/robert-kridel',
    scholar_id: 'JFRauOgAAAAJ',
    group: 'Clinician',
  },
  CCG: {
    lab: 'Kumar',
    PI: 'Sushant Kumar',
    website: 'https://ccglab-uhn.github.io/',
    scholar_id: 'ujvDsFUAAAAJ',
    group: 'Scientist',
  },
  Leighl: {
    lab: 'Leighl',
    PI: 'Natasha Leighl',
    website: 'https://www.uhnresearch.ca/researcher/natasha-b-leighl',
    scholar_id: 'H0im8BwAAAAJ',
    group: 'Clinician',
  },
  'Liu F.': {
    lab: 'Liu F.',
    PI: 'Fei-Fei Liu',
    website: 'http://wwwlabs.uhnresearch.ca/liu/',
    scholar_id: '45MCKfkAAAAJ',
    group: 'Clinician',
  },
  'Liu G.': {
    lab: 'Liu G.',
    PI: 'Geoffrey Liu',
    website: 'https://www.uhncombiel.com/about-the-liu-lab',
    scholar_id: 'Zjy1VdkAAAAJ',
    group: 'Scientist',
  },
  Lupien: {
    lab: 'Lupien',
    PI: 'Mathieu Lupien',
    website: 'https://www.pmgenomics.ca/lupienlab/',
    scholar_id: 'tepqzFEAAAAJ',
  },
  Minden: {
    lab: 'Minden',
    PI: 'Mark Minden',
    website: 'https://medbio.utoronto.ca/faculty/minden',
    scholar_id: '',
    group: 'Scientist',
  },
  Moran: {
    lab: 'Moran',
    PI: 'Michael Moran',
    website: 'https://www.sickkids.ca/en/staff/m/michael-f-moran/',
    scholar_id: 'Zx8sm6cAAAAJ',
    group: 'Scientist',
  },
  Pugh: {
    lab: 'Pugh',
    PI: 'Trevor Pugh',
    website: 'https://pughlab.org/',
    scholar_id: 'dVqnYDUAAAAJ',
    group: 'Scientist',
  },
  Schwartz: {
    lab: 'Schwartz',
    PI: 'Gregory Schwartz',
    website: 'https://medbio.utoronto.ca/faculty/schwartz',
    scholar_id: 'zNWiqkUAAAAJ',
    group: 'Scientist',
  },
  Seuntjens: {
    lab: 'Seuntjens',
    PI: 'Jan Seuntjens',
    website: 'https://medbio.utoronto.ca/faculty/seuntjens',
    scholar_id: 'U65WeBcAAAAJ',
    group: 'Scientist',
  },
  Xu: {
    lab: 'Xu',
    PI: 'Wei Xu',
    website: 'https://www.dlsph.utoronto.ca/faculty-profile/xu-wei/',
    scholar_id: 'bD0-KagAAAAJ',
    group: 'Clinician',
  },
};

export default labs;
